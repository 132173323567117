<template>
  <div v-if="hasStages">
    <ReportByStages />
  </div>
  <div v-else>
    <ReportByDepartures />
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import ReportByDepartures from "./departures/Departures";
import ReportByStages from "./stages/Stages";

const store = useStore();

const getWork = computed(() => store.getters.getWork);

const hasSectors = computed(() => {
  return getWork.value && getWork.value.useSectors;
});

const hasStages = ref(false);

if (hasSectors.value) {
  const getSectors = computed(() => store.getters.getSectors);
  const stages = ref([]);

  onMounted(async () => {
    await store.dispatch("getAllSectors");
    stages.value = [
      ...new Set(getSectors.value?.filter((s) => s.stage).map((s) => s.stage)),
    ];

    hasStages.value = stages.value.length > 0;
  });
} else {
  hasStages.value = false;
}
</script>
