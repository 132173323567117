<template>
  <div class="min-h-full overflow-x-scroll">
    <nav
      class="tabs flex flex-row pt-5 border-b px-6 fixed bg-white w-full z-10"
    >
      <button
        class="
          tab
          active
          text-gray-600
          py-2
          px-6
          block
          hover:text-blue-500
          focus:outline-none
        "
        :class="
          mode === 'accumulative'
            ? 'font-medium border-b-4 border-blue-500 text-blue-500'
            : ''
        "
        @click="mode = 'accumulative'"
      >
        Reporte de productividad
      </button>
    </nav>

    <div>
      <div class="p-4 xl:w-full lg:w-screen pt-20">
        <div class="lg:p-5 flex align-start p-5">
          <div class="w-80">
            <Multiselect
              v-if="getDepartures && getDepartures.length"
              v-model="filterDepartureKey"
              placeholder="Todas las partidas"
              :searchable="true"
              :min-chars="1"
              noOptionsText="No hay partidas"
              noResultsText="No hay resultados"
              :options="getDeparturesIsToCalculateIp"
            />
          </div>
        </div>

        <div
          v-show="loadingResume || loadingReport"
          class="flex justify-center"
        >
          <img :src="require('@/assets/loader.gif')" width="250" />
        </div>

        <div
          v-if="showChart && !refreshChart"
          v-show="!loadingReport && chartData"
        >
          <div class="flex justify-between my-2 px-5">
            <select
              v-model="chartMode"
              class="
                relative
                py-3
                px-2
                w-40
                bg-white
                rounded-sm
                border
                outline-none
                text-gray-700
                placeholder-gray-400
              "
            >
              <option value="daily">Días</option>
              <option value="weekly">Semanas</option>
              <option value="accumulated">Acumulado</option>
            </select>

            <div>
              <div v-if="isToExportDetail" class="flex">
                <div class="flex">
                  <p class="text-sm pl-5 text-gray-40 flex my-auto">Inicio:</p>
                  <input
                    v-model="detailDateFrom"
                    type="date"
                    class="
                      relative
                      p-1
                      bg-white
                      rounded
                      border
                      outline-none
                      text-gray-700
                      placeholder-gray-400
                      focus:outline-none
                      px-4
                      lg:w-40
                    "
                  />
                </div>
                <div class="flex">
                  <p class="text-sm pl-5 text-gray-400 flex my-auto">Fin:</p>
                  <input
                    v-model="detailDateTo"
                    type="date"
                    class="
                      relative
                      p-1
                      bg-white
                      rounded
                      border
                      outline-none
                      text-gray-700
                      placeholder-gray-400
                      focus:outline-none
                      px-4
                      lg:w-40
                    "
                  />
                </div>
                <button
                  class="
                    flex
                    text-white
                    border
                    text-sm
                    font-medium
                    bg-primary
                    border-primary
                    py-2
                    ml-4
                    px-3
                    my-auto
                  "
                  :disabled="loadingExportDetail"
                  @click="onExportDetail"
                >
                  <LoadingButtonIcon v-if="loadingExportDetail" />
                  Exportar
                </button>
              </div>

              <button
                v-else
                class="
                  text-white
                  border
                  text-sm
                  font-medium
                  bg-greenDark
                  border-greenDark
                  py-2
                  px-5 px-6
                  mx-2
                  flex
                "
                @click="isToExportDetail = true"
              >
                Exportar detalle
              </button>
            </div>
          </div>

          <VueHighcharts
            type="chart"
            :redrawOnUpdate="true"
            :oneToOneUpdate="true"
            :animateOnUpdate="true"
            :options="chartOptions"
          />

          <div class="text-center mt-10">
            <label class="font-bold">IP acumulado</label>
            <h1
              class="font-medium"
              :class="
                ipAccumulated < departureBudgetIp
                  ? 'text-green-500'
                  : 'text-red-500'
              "
              :style="{ fontSize: '60px' }"
            >
              {{ ipAccumulated.toFixed(2) }} HH/{{ departureUnit }}
            </h1>
          </div>

          <div class="text-center mt-10">
            <label class="font-bold">Avance acumulado</label>
            <h1
              class="font-medium"
              :class="percentage <= 100 ? 'text-green-500' : 'text-red-500'"
              :style="{ fontSize: '60px' }"
            >
              {{ advanceAccumulated.toFixed(2) }} {{ departureUnit }} ({{
                percentage.toFixed(2)
              }}%)
            </h1>
          </div>
          <div class="text-center mt-10">
            <label class="font-bold">HH ganadas/perdidas hoy</label>
            <h1
              class="font-medium"
              :class="hhWonLost > 0 ? 'text-green-500' : 'text-red-500'"
              :style="{ fontSize: '60px' }"
            >
              {{ hhWonLost.toFixed(2) }}
            </h1>
          </div>
          <!--
            <div class="text-center mt-10">
              <label class="font-bold">HH ganadas/perdidas a fin de obra</label>
              <h1
                class="font-medium"
                :class="
                  hhWonLostToEndWork > 0 ? 'text-green-500' : 'text-red-500'
                "
                :style="{ fontSize: '60px' }"
              >
                {{ hhWonLostToEndWork.toFixed(2) }}
              </h1>
            </div>
            -->
        </div>

        <div
          v-if="getDeparturesIpResume"
          v-show="!loadingResume && !filterDepartureKey"
          class="grid relative w-full lg:px-10 px-5"
        >
          <div class="mb-10">
            <VueHighcharts
              type="chart"
              :redrawOnUpdate="true"
              :oneToOneUpdate="true"
              :animateOnUpdate="true"
              :options="chartOptionsResumeDetail"
            />
          </div>
          <div class="flex justify-end mb-10">
            <!-- test -->
            <div class="flex">
              <p class="text-sm pl-5 flex my-auto">Inicio:</p>
              <input
                v-model="dateFrom"
                type="date"
                class="
                  relative
                  p-1
                  bg-white
                  rounded
                  border
                  outline-none
                  text-gray-700
                  placeholder-gray-400
                  focus:outline-none
                  px-4
                  lg:w-40
                "
              />
            </div>
            <div class="flex">
              <p class="text-sm pl-5 flex my-auto">Fin:</p>
              <input
                v-model="dateTo"
                type="date"
                class="
                  relative
                  p-1
                  bg-white
                  rounded
                  border
                  outline-none
                  text-gray-700
                  placeholder-gray-400
                  focus:outline-none
                  px-4
                  lg:w-40
                "
              />
            </div>
            <button
              class="
                flex
                text-white
                border
                text-sm
                font-medium
                bg-primary
                border-primary
                py-2
                ml-4
                px-3
                my-auto
              "
              :disabled="loadingExportResume"
              @click="onExportResume"
            >
              <LoadingButtonIcon v-if="loadingExportResume" />
              Exportar
            </button>
          </div>

          <div class="grid grid-cols-3 gap-4 mb-5 pt-2">
            <div></div>
            <div
              class="
                block
                max-w-sm
                p-6
                bg-white
                border border-gray-100
                rounded-lg
                shadow
                dark:bg-gray-800 dark:border-gray-700
                text-center
              "
            >
              <h1
                style="font-size: 24px"
                class="font-medium"
                :class="hhWonLostTotal > 0 ? 'text-green-500' : 'text-red-500'"
              >
                {{ hhWonLostTotal.toFixed(2) }}
              </h1>
              <span>HH ganadas/perdidas</span>
            </div>
            <div></div>
            <!--
              <div
                class="
                  block
                  max-w-sm
                  p-6
                  bg-white
                  border border-gray-100
                  rounded-lg
                  shadow
                  dark:bg-gray-800 dark:border-gray-700
                  text-center
                "
              >
                <h1
                  style="font-size: 24px"
                  class="font-medium"
                  :class="
                    hhWonLostToEndWorkTotal > 0
                      ? 'text-green-500'
                      : 'text-red-500'
                  "
                >
                  {{ hhWonLostToEndWorkTotal.toFixed(2) }}
                </h1>
                <span>HH ganadas/perdidas a fin de obra</span>
              </div>
              -->
          </div>

          <div class="overflow-x-scroll">
            <table class="w-full">
              <thead>
                <tr class="text-sm font-bold text-secondary">
                  <th class="py-2 text-left"></th>
                  <th class="py-2 text-left">Código</th>
                  <th
                    class="px-6 py-2 whitespace-nowrap"
                    :style="{ width: '250px !important' }"
                  >
                    Partida
                  </th>
                  <th class="px-6 py-2">IP presupuesto</th>
                  <th class="px-6 py-2">IP acumulado</th>
                  <th class="px-2 py-2">HH ganadas/perdidas</th>
                  <th class="px-6 py-2">Avance acumulado</th>
                  <th class="px-6 py-2">HH consumidas</th>
                  <th class="px-6 py-2">% de HH consumidas</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-300">
                <template
                  v-for="(departureIp, i) in getDeparturesIpResume"
                  :key="i"
                >
                  <tr>
                    <td class="p-4">
                      <button
                        v-if="departureIp.subdepartures"
                        @click="onToggleCollapse(departureIp)"
                        class="pt-4"
                      >
                        <svg
                          :class="{
                            'transform rotate-180': departureIp.isCollapsed,
                          }"
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-4 w-4 cursor-pointer"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </button>
                    </td>
                    <td class="px-4 py-4 text-sm text-left my-auto">
                      {{ departureIp.departure.code }}
                    </td>
                    <td
                      class="px-4 py-4 text-sm text-left whitespace-nowrap"
                      :style="{ width: '250px !important' }"
                    >
                      {{ departureIp.departure.name }}
                    </td>
                    <td class="px-2 py-4">
                      <div
                        class="text-sm text-center text-green-500 font-medium"
                      >
                        {{
                          departureIp.budgetIp
                            ? departureIp.budgetIp.toFixed(3)
                            : "—"
                        }}
                      </div>
                    </td>
                    <td class="px-2 py-4">
                      <div
                        class="text-sm text-center font-medium"
                        :class="
                          departureIp.ipAccumulated <= departureIp.budgetIp
                            ? 'text-green-500'
                            : 'text-red-500'
                        "
                      >
                        {{
                          departureIp.ipAccumulated
                            ? departureIp.ipAccumulated.toFixed(3)
                            : "—"
                        }}
                      </div>
                    </td>
                    <td class="px-2 py-4">
                      <div
                        class="text-sm uppercase font-medium text-center"
                        :class="
                          departureIp.hhWonLost > 0
                            ? 'text-green-500'
                            : 'text-red-500'
                        "
                      >
                        {{ departureIp.hhWonLost.toFixed(2) }}
                      </div>
                    </td>
                    <td
                      class="
                        px-4
                        py-4
                        text-sm
                        font-medium
                        text-center text-green-500
                      "
                    >
                      <span>
                        {{ departureIp.advanceAccumulated.toFixed(2) }}
                        {{ departureIp.departure.unit }}
                      </span>
                    </td>
                    <td
                      class="
                        px-4
                        py-4
                        text-sm text-center text-green-500
                        font-medium
                      "
                    >
                      <span>
                        {{ departureIp.workHoursAccumulated.toFixed(2) }}
                      </span>
                    </td>
                    <td
                      v-if="!departureIp.subdepartures"
                      class="px-4 py-4 text-sm font-medium text-center"
                      :class="
                        departureIp.percentage <= 100
                          ? 'text-green-500'
                          : 'text-red-500'
                      "
                    >
                      <div v-if="!isNaN(departureIp.percentage)">
                        {{
                          departureIp.percentage
                            ? `${departureIp.percentage.toFixed(2)}%`
                            : ""
                        }}
                      </div>
                      <div
                        v-if="
                          departureIp.percentage !== undefined &&
                          !isNaN(departureIp.percentage)
                        "
                        class="
                          w-full
                          bg-gray-200
                          rounded-full
                          h-2.5
                          mb-4
                          dark:bg-gray-700
                        "
                      >
                        <div
                          class="h-2.5 rounded-full"
                          :class="
                            departureIp.percentage <= 100
                              ? 'bg-green-500'
                              : 'bg-red-500'
                          "
                          :style="{
                            width: `${
                              departureIp.percentage >= 100
                                ? 100
                                : departureIp.percentage
                            }%`,
                          }"
                        ></div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="departureIp.isCollapsed">
                    <td colspan="100%">
                      <div class="px-10 py-5">
                        <h3 class="text-primary">Subpartidas</h3>
                      </div>
                      <div>
                        <table class="w-full">
                          <thead>
                            <tr class="text-sm font-bold text-secondary">
                              <th class="px-6 text-left"></th>
                              <th class="py-2 text-left">Código</th>
                              <th
                                class="px-6 py-2 whitespace-nowrap"
                                :style="{ width: '250px !important' }"
                              >
                                Partida
                              </th>
                              <th class="px-6 py-2">IP presupuesto</th>
                              <th class="px-6 py-2">IP acumulado</th>
                              <th class="px-2 py-2">HH ganadas/perdidas</th>
                              <th class="px-6 py-2">Avance acumulado</th>
                              <th class="px-6 py-2">HH consumidas</th>
                              <th class="px-6 py-2">% de HH consumidas</th>
                            </tr>
                          </thead>
                          <tbody class="bg-white divide-y divide-gray-300">
                            <tr
                              v-for="(
                                subdepartureIp, i
                              ) in departureIp.subdepartures"
                              :key="i"
                            >
                              <td class="p-4"></td>
                              <td class="px-4 py-4 text-sm text-left my-auto">
                                {{ subdepartureIp.departure.code }}
                              </td>
                              <td
                                class="
                                  px-4
                                  py-4
                                  text-sm text-left
                                  whitespace-nowrap
                                "
                                :style="{ width: '250px !important' }"
                              >
                                {{ subdepartureIp.departure.name }}
                              </td>
                              <td
                                class="
                                  px-4
                                  py-4
                                  text-sm text-center text-green-500
                                  font-medium
                                "
                              >
                                {{
                                  subdepartureIp.departure.budgetIp
                                    ? subdepartureIp.departure.budgetIp.toFixed(
                                        3
                                      )
                                    : "—"
                                }}
                              </td>
                              <td
                                class="
                                  px-2
                                  py-4
                                  text-sm
                                  font-medium
                                  my-auto
                                  text-center
                                "
                                :class="
                                  subdepartureIp.ipAccumulated <=
                                  subdepartureIp.departure.budgetIp
                                    ? 'text-green-500'
                                    : 'text-red-500'
                                "
                              >
                                {{
                                  subdepartureIp.ipAccumulated
                                    ? subdepartureIp.ipAccumulated.toFixed(3)
                                    : "—"
                                }}
                              </td>
                              <td class="px-2 py-4">
                                <div
                                  class="
                                    text-sm
                                    uppercase
                                    font-medium
                                    text-center
                                  "
                                  :class="
                                    subdepartureIp.hhWonLost > 0
                                      ? 'text-green-500'
                                      : 'text-red-500'
                                  "
                                >
                                  {{ subdepartureIp.hhWonLost.toFixed(2) }}
                                </div>
                              </td>
                              <td
                                class="
                                  px-4
                                  py-4
                                  text-sm
                                  font-medium
                                  text-center text-green-500
                                "
                              >
                                <span>
                                  {{
                                    subdepartureIp.advanceAccumulated.toFixed(2)
                                  }}
                                  {{ subdepartureIp.departure.unit }}
                                </span>
                              </td>
                              <td
                                class="
                                  px-4
                                  py-4
                                  text-sm text-center text-green-500
                                  font-medium
                                "
                              >
                                <span>
                                  {{
                                    subdepartureIp.workHoursAccumulated.toFixed(
                                      2
                                    )
                                  }}
                                </span>
                              </td>
                              <td
                                class="
                                  px-4
                                  py-4
                                  text-sm
                                  font-medium
                                  text-center
                                "
                                :class="
                                  subdepartureIp.percentage <= 100
                                    ? 'text-green-500'
                                    : 'text-red-500'
                                "
                              >
                                <div
                                  v-if="
                                    !isNaN(subdepartureIp.percentage) &&
                                    subdepartureIp.percentage >= 0
                                  "
                                >
                                  {{
                                    subdepartureIp.percentage
                                      ? `${subdepartureIp.percentage.toFixed(
                                          2
                                        )}%`
                                      : ""
                                  }}
                                </div>
                                <div
                                  v-if="
                                    subdepartureIp.percentage !== undefined &&
                                    subdepartureIp.percentage >= 0
                                  "
                                  class="
                                    w-full
                                    bg-gray-200
                                    rounded-full
                                    h-2.5
                                    mb-4
                                    dark:bg-gray-700
                                  "
                                >
                                  <div
                                    class="h-2.5 rounded-full"
                                    :class="
                                      subdepartureIp.percentage <= 100
                                        ? 'bg-green-500'
                                        : 'bg-red-500'
                                    "
                                    :style="{
                                      width: `${
                                        subdepartureIp.percentage >= 100
                                          ? 100
                                          : subdepartureIp.percentage
                                      }%`,
                                    }"
                                  ></div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch, nextTick, onUnmounted } from "vue";
import VueHighcharts from "vue3-highcharts";
import Multiselect from "@vueform/multiselect";
import { useStore } from "vuex";
import dayjs from "dayjs";

const store = useStore();

const mode = ref("accumulative");
const dateFrom = ref("2021-01-01");
const dateTo = ref("");
const filterDepartureKey = ref("");
const showChart = ref(false);
const chartData = ref([]);
const chartDataResumeDetail = ref([]);
const ipAccumulated = ref(0);
const advanceAccumulated = ref(0);
const hhWonLost = ref(0);
const hhWonLostToEndWork = ref(0);
const departureBudgetMetered = ref("");
const departureBudgetIp = ref("");
const departureUnit = ref("");
const percentage = ref(0);
const refreshChart = ref(false);
const loadingReport = ref(false);
const chartMode = ref("daily");
const isToExportDetail = ref(false);
const loadingExportDetail = ref(false);
const detailDateFrom = ref("");
const detailDateTo = ref("");
const loadingResume = ref(true);
const hhWonLostTotal = ref(0);
const hhWonLostToEndWorkTotal = ref(0);
const loadingExportResume = ref(false);

const getDepartures = computed(() => store.getters.getDepartures);
const getDeparturesIsToCalculateIp = computed(() => {
  return store.getters.getDeparturesIsToCalculateIp.map((departure) => ({
    value: departure.key,
    label: departure.name,
  }));
});
const getDepartureIpReport = computed(() => store.getters.getDepartureIpReport);
const getDeparturesIpResume = computed(
  () => store.getters.getDeparturesIpResume
);
const getDeparturesIpResumeDetail = computed(
  () => store.getters.getDeparturesIpResumeDetail
);
const chartOptions = computed(() => ({
  chart: { type: "line" },
  title: { text: "" },
  xAxis: {
    type: "datetime",
    dateTimeLabelFormats: {
      month: "%e. %b",
      year: "%b",
    },
  },

  yAxis: {
    title: { text: `IP (HH/${getDepartureIpReport.value[0].departure.unit})` },
    labels: {
      formatter: function () {
        return Math.round(this.value); // Redondea los valores de los labels a enteros
      },
    },
  },
  credits: { enabled: false },
  series: chartData.value,
  tooltip: {
    headerFormat: "<b>{series.name}</b><br>",
    pointFormat: `{point.x:%e. %b}: {point.y:.2f} HH/${getDepartureIpReport.value[0].departure.unit}`,
  },
  plotOptions: {
    column: {
      pointPadding: 0,
      borderWidth: 0,
    },
    line: {
      dataLabels: {
        enabled: true,
        formatter: function () {
          return this.y.toFixed(2);
        },
      },
      enableMouseTracking: true,
    },
    series: {
      events: {
        legendItemClick: function (e) {
          e.preventDefault();
        },
      },
    },
  },
  colors: ["#5dbc53", "#38ACFF"],
}));
const chartOptionsResumeDetail = computed(() => ({
  chart: { type: "line" },
  title: { text: "HH ganadas/perdidas por semana" },
  xAxis: {
    type: "datetime",
    dateTimeLabelFormats: {
      month: "%e. %b",
      year: "%b",
    },
  },
  yAxis: {
    title: { text: "HH ganadas/perdidas" },
  },
  credits: { enabled: false },
  series: chartDataResumeDetail.value,
  tooltip: {
    headerFormat: "<b>{series.name}</b><br>",
    pointFormat: `{point.x:%e. %b}: {point.y:.2f}`,
  },
  plotOptions: {
    column: {
      pointPadding: 0,
      borderWidth: 0,
    },
    line: {
      dataLabels: {
        enabled: true,
        formatter: function () {
          return this.y.toFixed(2);
        },
      },
      enableMouseTracking: true,
    },
    series: {
      events: {
        legendItemClick: function (e) {
          e.preventDefault();
        },
      },
    },
  },
  colors: ["#5dbc53", "#38ACFF"],
}));

const loadDepartureIpResume = async (showDetail) => {
  try {
    loadingResume.value = true;

    await store.dispatch("generateDepartureIpResume", {
      dateFrom: dateFrom.value,
      dateTo: dateTo.value,
      showDetail: showDetail,
    });
  } catch (error) {
    console.error(error);
  } finally {
    loadingResume.value = false;
  }
};

watch(
  () => dateTo.value,
  (currentValue, oldValue) => loadDepartureIpResume(!oldValue)
);

watch(
  () => dateFrom.value,
  () => loadDepartureIpResume()
);

const loadDepartureIpReport = async () => {
  if (!filterDepartureKey.value) {
    store.dispatch("resetDepartureIpReport");
    return;
  }

  try {
    loadingReport.value = true;

    await store.dispatch("generateDepartureIpReport", {
      departureKey: filterDepartureKey.value,
      dateTo: dayjs(new Date()).format("YYYY-MM-DD"),
      dateFrom: "2021-01-01",
    });
  } catch (error) {
    console.error(error);
  } finally {
    loadingReport.value = false;
  }
};

watch(
  () => filterDepartureKey.value,
  () => loadDepartureIpReport()
);

const formatDeparturesIpByWeekly = (departuresIp) => {
  // Helper function to get the Monday of the week for a given date
  function getMonday(d) {
    // d = new Date(d);
    // const day = d.getDay(),
    //   diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    // return new Date(d.setDate(diff)).toISOString().split("T")[0];
    const date = dayjs(d);
    const day = date.day();
    const diff = day === 0 ? -6 : 1 - day; // adjust when day is Sunday
    return date.add(diff, "day").format("YYYY-MM-DD");
  }

  // Step 1: Group by departureKey and date
  const groupedByDate = departuresIp.reduce((acc, curr) => {
    const key = `${curr.departureKey}_${curr.date}`;
    if (!acc[key]) {
      acc[key] = { ...curr };
    } else {
      acc[key].workHours += curr.workHours;
      acc[key].advance += curr.advance;
    }
    return acc;
  }, {});

  const groupedByDateArray = Object.values(groupedByDate);

  // Step 2: Adjust dates to the Monday of their week
  groupedByDateArray.forEach((item) => {
    item.date = getMonday(item.date);
  });

  // Step 3: Group by departureKey and adjusted date
  const finalGrouped = groupedByDateArray.reduce((acc, curr) => {
    const key = `${curr.departureKey}_${curr.date}`;
    if (!acc[key]) {
      acc[key] = { ...curr };
    } else {
      acc[key].workHours += curr.workHours;
      acc[key].advance += curr.advance;
    }
    return acc;
  }, {});

  const finalArray = Object.values(finalGrouped);
  return finalArray;
};

const loadDepartureIpReportGraph = async () => {
  let data = [];
  let departuresIp = getDepartureIpReport.value.map((r) => ({
    ...r,
    weekday: dayjs(r.date).day(),
  }));

  if (chartMode.value === "daily") {
    departuresIp = departuresIp.filter((r) => r.ip > 0);

    data.push(
      {
        name: "IP presupuesto",
        data: departuresIp.map((r) => [
          Date.parse(r.date),
          r.departure.budgetIp,
        ]),
      },
      {
        name: "IP real",
        data: departuresIp.map((r) => [Date.parse(r.date), r.ip]),
      }
    );
  }

  if (chartMode.value === "weekly") {
    departuresIp = formatDeparturesIpByWeekly(departuresIp);

    data.push({
      name: "IP semanal",
      data: departuresIp
        .map((r) => [Date.parse(r.date), r.workHours / r.advance])
        .filter((r) => r[1] > 0),
    });
  }

  if (chartMode.value === "accumulated") {
    departuresIp = departuresIp.filter((r) => r.ip > 0);

    data.push(
      {
        name: "IP presupuesto",
        data: departuresIp.map((r) => [
          Date.parse(r.date),
          r.departure.budgetIp,
        ]),
      },
      {
        name: "IP acumulado",
        data: departuresIp.map((r) => [Date.parse(r.date), r.ipAccumulated]),
      }
    );
  }

  showChart.value = false;
  nextTick(() => {
    showChart.value = true;
    chartData.value = data;
  });
};

watch(
  () => chartMode.value,
  () => {
    loadDepartureIpReportGraph();
  }
);

watch(
  () => getDepartureIpReport.value,
  (currentValue) => {
    if (!currentValue?.length) {
      showChart.value = false;
      return;
    }

    if (currentValue && currentValue.length) {
      const lastValue = currentValue[currentValue.length - 1];
      hhWonLost.value = lastValue.hhWonLost;
      hhWonLostToEndWork.value = lastValue.hhWonLostToEndWork;
      ipAccumulated.value = lastValue.ipAccumulated;
      advanceAccumulated.value = lastValue.advanceAccumulated;
      departureBudgetMetered.value = lastValue.departure.budgetMetered;
      departureBudgetIp.value = lastValue.departure.budgetIp;
      departureUnit.value = lastValue.departure.unit;
      percentage.value =
        (lastValue.advanceAccumulated * 100) /
        lastValue.departure.budgetMetered;
    }

    loadDepartureIpReportGraph();
  },
  { deep: true }
);

watch(
  () => getDeparturesIpResume.value,
  (currentValue) => {
    if (!currentValue?.length) return;

    let total = 0;
    let toEndTotal = 0;

    currentValue.forEach((v) => {
      if (v.hhWonLost === undefined && !v.subdepartures?.length) return;

      if (v.subdepartures?.length && v.hhWonLost === undefined) {
        v.subdepartures.forEach((sd) => {
          total += sd.hhWonLost;
        });
      } else {
        total += v.hhWonLost;
        toEndTotal += v.hhWonLostToEndWork;
      }
    });

    hhWonLostTotal.value = total;
    hhWonLostToEndWorkTotal.value = toEndTotal;
  }
);

watch(
  () => getDeparturesIpResumeDetail.value,
  (currentValue) => {
    let data = [
      {
        name: "Semana",
        data: currentValue.map((r) => [Date.parse(r.date), r.hhWonLost]),
      },
    ];

    chartDataResumeDetail.value = data;
  },
  { deep: true }
);

const init = async () => {
  const today = dayjs(new Date()).format("YYYY-MM-DD");
  dateTo.value = today;
  detailDateTo.value = today;
  detailDateFrom.value = dayjs(today).subtract(30, "day").format("YYYY-MM-DD");

  await store.dispatch("getAllDepartures", { includeSubdepartures: true });
};

init();

const onExportDetail = async () => {
  try {
    loadingExportDetail.value = true;

    if (detailDateTo.value < detailDateFrom.value) return;

    const reportUrl = await store.dispatch("generateDepartureIpReportExcel", {
      departureKey: filterDepartureKey.value,
      dateFrom: detailDateFrom.value,
      dateTo: detailDateTo.value,
    });

    let link = document.createElement("a");
    link.href = reportUrl;
    link.setAttribute("download", "productividad-detalle.xlsx");
    document.body.appendChild(link);
    link.click();

    isToExportDetail.value = false;
  } catch (error) {
    console.error(error);
  } finally {
    loadingExportDetail.value = false;
  }
};

const onExportResume = async () => {
  try {
    loadingExportResume.value = true;

    const reportUrl = await store.dispatch("generateDepartureIpResumeExcel", {
      dateFrom: dateFrom.value,
      dateTo: dateTo.value,
    });

    let link = document.createElement("a");
    link.href = reportUrl;
    link.setAttribute("download", "productividad-resumen.xlsx");
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error(error);
  } finally {
    loadingExportResume.value = false;
  }
};

const onToggleCollapse = (departureIp) => {
  departureIp.isCollapsed = !departureIp.isCollapsed;
};

onUnmounted(() => {
  store.dispatch("resetDeparturesIpResume");
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  --ms-option-bg-selected: #8b8b8b;
  --ms-option-bg-selected-pointed: #8b8b8b;
}
</style>
